<template>
  <div id="rent-to-own-page">
    <div
      class="banner cvr-bg medium-title"
      style="background-image: url('/img/dummy/about/rto/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ title }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-sidebar">
      <div class="wrapper rowflex">
        <sidebar-about></sidebar-about>
        <article class="main wider">
          <div class="inner-rto">
            <div class="copy">
              <h2>
                <span>{{ title }}</span>
              </h2>
              <div class="format-text">
                <p>{{ descriptions.what }}</p>
              </div>
              <!-- end of format text -->
            </div>
            <!-- end of copy -->
            <div class="copy">
              <h2>
                <span>{{ $t("aboutUs.HowDoesItWork") }}</span>
              </h2>
              <div class="format-text">
                <p>{{ descriptions.how }}</p>
              </div>
              <!-- end of format text -->
            </div>
            <!-- end of copy -->
            <div class="list">
              <template v-for="(content, index) in descriptions.contents">
                <div class="item" :key="index">
                  <b>{{ index + 1 }}</b>
                  <div class="wrap">
                    <figure>
                      <img
                        :src="`/img/dummy/about/rto/${index + 2}.png`"
                        width="110"
                      />
                    </figure>
                    <div class="format-text">
                      <p>{{ content.value }}</p>
                    </div>
                    <!-- end of format text -->
                  </div>
                  <!--end of wrap -->
                </div>
                <!-- end of item -->
              </template>
            </div>
            <!-- end of list -->

            <div class="accord">
              <h2>{{ $t("aboutUs.InfoAndExampleRTO") }}</h2>
              <div class="wrap">
                <template v-for="(content, index) in contents">
                  <div
                    class="accord-item"
                    :key="index"
                    @click="expandContent(index)"
                    :class="{ expanded: showContent == index }"
                  >
                    <div class="acc-head">
                      <h3>{{ content.header }}</h3>
                    </div>
                    <div
                      class="acc-body"
                      :style="{
                        display: showContent == index ? 'block' : 'none'
                      }"
                    >
                      <div class="format-text">
                        <p>{{ content.subHeader }}</p>
                        <div v-html="content.body"></div>
                      </div>
                      <!-- end of format text -->
                    </div>
                    <!-- end of acc body -->
                  </div>
                </template>
              </div>
              <!-- end of wrap -->
            </div>
          </div>
          <!-- end of innner rto -->
        </article>
        <!-- end of main -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner aside -->
  </div>
</template>

<script>
import SidebarAbout from "./SidebarAbout";
export default {
  name: "RentToOwn",
  metaInfo: {
    title: "Rent To Own"
  },
  components: {
    SidebarAbout
  },
  data() {
    return {
      title: null,
      menuId: 4,
      contents: [],
      descriptions: {},
      example: [],
      showContent: null
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/about-us/${this.menuId}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          if (response.data.success) {
            this.title = response.data.data.title;
            this.contents = response.data.data.contents;
            this.descriptions = response.data.data.descriptions;
            this.example = response.data.data.example;
          }
        });
    },
    expandContent(index) {
      if (this.showContent == index) {
        this.showContent = null;
      } else {
        this.showContent = index;
      }
    }
  }
};
</script>
